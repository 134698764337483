import React, { useState, useEffect } from "react";

// components
import HeaderContent from "../../../layouts/HeaderContent";
import AsyncCreatableSelect from "react-select/async-creatable";
import LoadingPage from "../../../components/LoadingPage";
import { Button } from "../../../components/Button";
import {
  Input,
  InputCurrency,
  MessageError,
  Textarea,
} from "../../../components/Input";
import { Modal } from "../../../components/Modal";

// icons
import { BsFillSave2Fill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { FiSave, FiXCircle } from "react-icons/fi";

// libraries
import axios from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { swNormal } from "../../../utils/sw";
import { rupiah, rupiahToNumber } from "../../../utils/strings";
import {
  useMutation,
  QueryClient,
  useQueryClient,
  useQuery,
} from "react-query";
import { toastSuccess, toastError } from "../../../utils/toast";
import user from "../../../utils/user";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: "3rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    borderRadius: "10px",
    borderWidth: "1px",
    borderColor: "rgb(59 130 246 / 1)",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      // backgroundColor: isDisabled ? 'red' : 'blue',
      // color: '#FFF',
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const TutupKasir = () => {
  const [imageBase64, setImageBase64] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const [isAction, setIsAction] = useState(false);
  const [isSudahRekap, setIsSudahRekap] = useState(false);
  const [tanggalBuka, setTanggalBuka] = useState("");
  const [jamBuka, setJamBuka] = useState("");
  const [modalAwal, setModalAwal] = useState("");
  const [kasKeluar, setKasKeluar] = useState("");
  const [totalPenjualan, setTotalPenjualan] = useState("");
  const [isShowModalBukaKasir, setIsShowModalBukaKasir] = useState("");
  const [isShowModalTutupKasir, setIsShowModalTutupKasir] = useState("");
  const [isShowModalKasKeluar, setIsShowModalKasKeluar] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
    control,
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const breadcrumbs = [
    { link: "/", menu: "Home" },
    { link: "/order", menu: "Transaksi" },
    { link: "/tutup-kasir", menu: "Buka / Tutup Kasir" },
  ];

  const mutationTutupKasir = useMutation(
    async (data) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const response = await axios.post("rekapkas/tutup-kasir?email-staff=" + user.email, data);
      const res = response.data;

      if (res.meta.code != 200) {
        throw new Error(res.meta.message);
      }

      return res.data;
    },
    {
      onMutate: () => {
        setIsAction(true);
      },
      onSettled: async (data, error) => {
        fetchRekapKas();

        if (data) {
          clearErrors();
        }
      },
      onSuccess: async () => {
        localStorage.setItem("sudahRekap", false);
        setIsAction(false);
        toastSuccess("Kasir berhasil ditutup");
      },
      onError: async () => {
        setIsAction(false);
        toastError("Kasir gagal ditutup, Silahkan Coba Lagi");
      },
    }
  );

  const mutationBukaKasir = useMutation(
    async (data) => {
      data.modal_awal = rupiahToNumber(data.modal_awal);
      data.total_tunai = rupiahToNumber(data.total_tunai);

      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const response = await axios.post("rekapkas/buka-kasir?email-staff=" + user.email, data);
      const res = response.data;

      if (res.meta.code != 200) {
        throw new Error(res.meta.message);
      }

      return res.data;
    },
    {
      onMutate: () => {
        setIsAction(true);
      },
      onSettled: async (data, error) => {
        localStorage.setItem("sudahRekap", true);
        fetchRekapKas();

        if (data) {
          clearErrors();
        }
      },
      onSuccess: async () => {
        setIsAction(false);
        toastSuccess("Kasir berhasil dibuka");
      },
      onError: async () => {
        setIsAction(false);
        toastError("Kasir gagal dibuka, Silahkan Coba Lagi");
      },
    }
  );

  const mutationKasKeluar = useMutation(
    async (data) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const response = await axios.post("rekapkas/kas-keluar?email-staff=" + user.email, data);
      const res = response.data;

      if (res.meta.code != 200) {
        throw new Error(res.meta.message);
      }

      return res.data;
    },
    {
      onMutate: () => {
        setIsAction(true);
      },
      onSettled: async (data, error) => {
        fetchRekapKas();

        if (data) {
          clearErrors();
        }
      },
      onSuccess: async () => {
        setIsAction(false);
        toastSuccess("Kas keluar berhasil diisi");
      },
      onError: async () => {
        setIsAction(false);
        toastError("Kas keluar gagal diisi, Silahkan Coba Lagi");
      },
    }
  );

  const handleTutupKasir = async (data) => {
    data.total_tunai = rupiahToNumber(data.total_tunai);
    data.total_nontunai = rupiahToNumber(data.total_nontunai);
    await mutationTutupKasir.mutate(data);
  };

  const handleBukaKasir = async (data) => {
    await mutationBukaKasir.mutate(data);
  };

  const handleKasKeluar = async (data) => {
    data.kas_keluar = rupiahToNumber(data.kas_keluar);
    await mutationKasKeluar.mutate(data);
  };

  // fetch data
  const fetchRekapKas = async () => {
    setIsAction(true);
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const response = await axios.get(`rekapkas?email-staff=` + user.email);
      const res = await response.data.data;

      setTanggalBuka(res.tgl_buka ?? "");
      setJamBuka(res.waktu_buka ?? "");
      setModalAwal(res.modal_awal ?? "");
      setKasKeluar(res.kas_keluar ?? "");
      setTotalPenjualan(res.total_penjualan ?? "");
      setIsSudahRekap(true);
    } catch (error) {
      if (error.response.status == 400) {
        setIsSudahRekap(false);
      }
    } finally {
      setIsAction(false);
    }
  };

  useEffect(() => {
    fetchRekapKas();
  }, []);

  return (
    <React.Fragment>
      <HeaderContent title="Buka / Tutup Kasir" breadcrumbs={breadcrumbs} />
      {isAction ? (
        <div className="bg-white h-max px-6 rounded-lg mt-4">
          <LoadingPage />
        </div>
      ) : isSudahRekap ? (
        <div className="bg-white h-max px-6 rounded-lg mt-4">
          <h2 className="pt-4 text-lg font-semibold">
            <span className="text-custom-green">Buka</span>
            {" : "}
            {tanggalBuka + " " + jamBuka}
          </h2>
          <h2 className="pt-2 text-lg font-semibold">
            {"Total Transaksi : IDR " + rupiah(totalPenjualan)}
          </h2>
          <h2 className="pt-8 text-lg font-semibold">
            {"Modal Awal : IDR " + rupiah(modalAwal)}
          </h2>
          {kasKeluar != "" ? (
            <h2 className="pt-4 pb-4 text-lg font-semibold">
              {"Kas Keluar : IDR " + rupiah(kasKeluar)}
            </h2>
          ) : null}
          <div className="py-6 my-4">
            <label onClick={() => setIsShowModalTutupKasir(true)}>
              <span className="text-white select-none cursor-pointer hover:bg-slate-800 rounded px-4 py-3 px-6 mr-2 bg-custom-navy">
                Tutup Kasir
              </span>
            </label>
            <label onClick={() => setIsShowModalKasKeluar(true)}>
              <span className="text-white select-none cursor-pointer hover:bg-yellow-700 rounded px-4 py-3 px-6 mr-2 bg-yellow-500">
                Kas Keluar
              </span>
            </label>
          </div>
        </div>
      ) : (
        <div className="bg-white h-max px-6 rounded-lg mt-4">
          <label onClick={() => setIsShowModalBukaKasir(true)}>
            <span className="text-white select-none cursor-pointer hover:bg-green-700 rounded px-4 py-3 px-12 mr-2 bg-green-500">
              Buka Kasir
            </span>
          </label>
        </div>
      )}

      <div
        className={`fixed inset-0 z-30 overflow-y-auto ${
          !isShowModalBukaKasir && "hidden"
        }`}
      >
        <div className="fixed inset-0 w-full h-full bg-black opacity-10"></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-90 max-w-lg p-4 mx-auto bg-white rounded-xl shadow-lg">
            <div className="grid my-4">
              <div className="relative">
                <InputCurrency
                  type="text"
                  placeholder="Masukkan modal awal"
                  name="modal_awal"
                  control={control}
                  rules={{ required: true }}
                  error={errors.modal_awal ? true : false}
                />
                {errors?.modal_awal && (
                  <span className="text-red-400 block mt-2">
                    Total Modal Awal Tidak Boleh Kosong
                  </span>
                )}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <button
                  type="button"
                  className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                  onClick={() => {
                    handleBukaKasir(getValues());
                    setIsShowModalBukaKasir(false);
                  }}
                >
                  Simpan
                </button>
                <button
                  className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-gray-600 focus:ring-2"
                  onClick={() => setIsShowModalBukaKasir(false)}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed inset-0 z-30 overflow-y-auto ${
          !isShowModalTutupKasir && "hidden"
        }`}
      >
        <div className="fixed inset-0 w-full h-full bg-black opacity-10"></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-90 max-w-lg p-4 mx-auto bg-white rounded-xl shadow-lg">
            <div className="grid my-4">
              <div className="grid grid-cols-10 gap-4 mb-4">
                <label className="label col-span-3">
                  <span className="label-text">Total Tunai</span>
                </label>
                <div className="relative col-span-7">
                  <InputCurrency
                    type="text"
                    placeholder="Masukkan total tunai"
                    name="total_tunai"
                    control={control}
                    rules={{ required: true }}
                    error={errors.total_tunai ? true : false}
                  />
                  {errors?.total_tunai && (
                    <span className="text-red-400 block mt-2">
                      Total tunai Tidak Boleh Kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-10 gap-4 mb-4">
                <label className="label col-span-3">
                  <span className="label-text">Total Non-Tunai</span>
                </label>
                <div className="relative col-span-7">
                  <InputCurrency
                    type="text"
                    placeholder="Masukkan total non tunai"
                    name="total_nontunai"
                    control={control}
                    rules={{ required: true }}
                    error={errors.total_nontunai ? true : false}
                  />
                  {errors?.total_nontunai && (
                    <span className="text-red-400 block mt-2">
                      Total non tunai Tidak Boleh Kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2 mt-4">
                <button
                  type="button"
                  className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                  onClick={() => {
                    handleTutupKasir(getValues());
                    setIsShowModalTutupKasir(false);
                  }}
                >
                  Simpan
                </button>
                <button
                  className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-gray-600 focus:ring-2"
                  onClick={() => setIsShowModalTutupKasir(false)}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed inset-0 z-30 overflow-y-auto ${
          !isShowModalKasKeluar && "hidden"
        }`}
      >
        <div className="fixed inset-0 w-full h-full bg-black opacity-10"></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-90 max-w-lg p-4 mx-auto bg-white rounded-xl shadow-lg">
            <div className="grid mb-4">
              <div className="grid mb-4">
                <div className="relative">
                  <InputCurrency
                    type="text"
                    placeholder="Masukkan total kas keluar"
                    name="kas_keluar"
                    control={control}
                    rules={{ required: true }}
                    error={errors.kas_keluar ? true : false}
                  />
                  {errors?.kas_keluar && (
                    <span className="text-red-400 block mt-2">
                      Total Kas Keluar Tidak Boleh Kosong
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2 mt-4">
                <button
                  type="button"
                  className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                  onClick={() => {
                    handleKasKeluar(getValues());
                    setIsShowModalKasKeluar(false);
                  }}
                >
                  Simpan
                </button>
                <button
                  className="w-full h-10 px-12 py-1 mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-gray-600 focus:ring-2"
                  onClick={() => setIsShowModalKasKeluar(false)}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<Modal
			        id="modal-tutup-kasir"
			        showButtonConfirm={true}
			        typeButtonConfirm="submit"
			        titleButtonConfirm="Tutup Kasir"
			        titleButtonClose="Batal"
			        showButtonClose={true}
			        titleModal="Tutup Kasir"
			        submitForm={handleSubmit(handleTutupKasir)}
			    >
			      	<div className="mt-4 mb-3">
				      	<div className="grid grid-cols-10 gap-4 mb-4">
				      		<label className="label col-span-3">
					          <span className="label-text">Total Tunai</span>
					        </label>
				      		<div className="relative col-span-7">
				      			<InputCurrency type="text" placeholder="Masukkan total tunai" name="total_tunai" control={control} rules={{required: true}} error={errors.total_tunai ? true : false}/>
				      			{errors?.total_tunai && <span className="text-red-400 block mt-2">Total tunai Tidak Boleh Kosong</span>}
				      		</div>
						</div>
						<div className="grid grid-cols-10 gap-4 mb-4">
				      		<label className="label col-span-3">
					          <span className="label-text">Total Non-Tunai</span>
					        </label>
				      		<div className="relative col-span-7">
				      			<InputCurrency type="text" placeholder="Masukkan total non tunai" name="total_nontunai" control={control} rules={{required: true}} error={errors.total_nontunai ? true : false}/>
				      			{errors?.total_nontunai && <span className="text-red-400 block mt-2">Total non tunai Tidak Boleh Kosong</span>}
				      		</div>
						</div>
					</div>
			    </Modal>
			    
			    <Modal
			        id="modal-kas-keluar"
			        showButtonConfirm={true}
			        typeButtonConfirm="submit"
			        titleButtonConfirm="Simpan"
			        titleButtonClose="Batal"
			        showButtonClose={true}
			        titleModal="Kas Keluar"
			        submitForm={handleSubmit(handleKasKeluar)}
			    >
			      	<div className="mt-4 mb-3">
				      	<div className="grid mb-4">
				      		<div className="relative">
				      			<InputCurrency type="text" placeholder="Masukkan total kas keluar" name="total_tunai" control={control} rules={{required: true}} error={errors.total_tunai ? true : false}/>
				      			{errors?.total_tunai && <span className="text-red-400 block mt-2">Total Kas Keluar Tidak Boleh Kosong</span>}
				      		</div>
						</div>
					</div>
			    </Modal>

			    <Modal
			        id="modal-buka-kasir"
			        showButtonConfirm={true}
			        typeButtonConfirm="submit"
			        titleButtonConfirm="Buka Kasir"
			        titleButtonClose="Batal"
			        showButtonClose={true}
			        titleModal="Buka Kasir"
			        submitForm={handleSubmit(handleBukaKasir)}
			    >
			      	<div className="mt-4 mb-3">
				      	<div className="grid mb-4">
				      		<div className="relative">
				      			<InputCurrency type="text" placeholder="Masukkan modal awal" name="modal_awal" control={control} rules={{required: true}} error={errors.modal_awal ? true : false}/>
				      			{errors?.modal_awal && <span className="text-red-400 block mt-2">Total Modal Awal Tidak Boleh Kosong</span>}
				      		</div>
								</div>
							</div>
			    </Modal>*/}
    </React.Fragment>
  );
};

export default TutupKasir;
