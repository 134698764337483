import React from 'react'
import { Outlet } from 'react-router-dom'

const Container = () => {

    return (
    	<div className="pb-6">
        	<Outlet/>
    	</div>
    );
};

export default Container;
