import React, {useState} from 'react'
import moment from 'moment'
import { rupiah } from '../../../../utils/strings'
import LoadingPage from "../../../../components/LoadingPage";

export const LaporanPenjualan = React.forwardRef((props, ref) => {
	moment.locale('id')

	let totalPenjualanKotor = 0
	let totalLabaKotor = 0
	let jumlahPenjualan = 0

	return (
		<div className="text-black mt-5 px-4 mb-5" ref={ref}>
			<h1 className="text-xl text-center font-bold mt-5">Bathiku</h1>
			<h2 className="text-md text-center font-semibold mb-4">Laporan Penjualan</h2>

			<div className="mb-3 flex justify-between">
				<span className="text-sm">Tanggal : {moment(props.tanggalAwal).format('DD-MM-YYYY')} s/d {moment(props.tanggalAkhir).format('DD-MM-YYYY')}</span>
			</div>

			{props.isAction ? (
				<LoadingPage/>
			) : (
				<table className="table w-full text-sm border-collapse border border-slate-400 table-auto mb-5">
					<thead>
						<tr className="text-sm font-semibold tracking-wide border-b border-slate-400">
							<th className="px-4 py-3 border border-slate-400">Tanggal</th>
							<th className="px-4 py-3 border border-slate-400">No Transaksi</th>
							<th className="px-4 py-3 border border-slate-400">Nama Kasir</th>
							<th className="px-4 py-3 border border-slate-400">Penjualan Kotor</th>
							<th className="px-4 py-3 border border-slate-400">Laba Kotor</th>
						</tr>
					</thead>
					<tbody>
						{props?.data.map((item, key) => {
							jumlahPenjualan += 1
							totalPenjualanKotor += item.total_jml_transaksi
							totalLabaKotor += item.nilai_laba

							return (
								<tr key={key}>
									<td className="text-center px-4 py-3 border border-slate-400">{moment(item.created_at).format('DD-MM-YYYY')}</td>
									<td className="text-center px-4 py-3 border border-slate-400">{item.invoice}</td>
									<td className="text-center px-4 py-3 border border-slate-400">{item.pegawai?.nama_pegawai ?? "-"}</td>
									<td className="text-right px-4 py-3 border border-slate-400">Rp. {rupiah(item.total_jml_transaksi)}</td>
									<td className="text-right px-4 py-3 border border-slate-400">Rp. {rupiah(item.nilai_laba)}</td>
								</tr>
							)
						})}
					</tbody>
					<tfoot>
						<tr>
							<th colSpan="3" className="text-right px-4 py-3 border border-slate-400">Total</th>
							<th className="text-right px-4 py-3 border border-slate-400">Rp. {rupiah(totalPenjualanKotor)}</th>
							<th className="text-right px-4 py-3 border border-slate-400">Rp. {rupiah(totalLabaKotor)}</th>
						</tr>
					</tfoot>
				</table>
			)}
		</div>
	)

});