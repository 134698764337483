import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import axios from "../utils/axios";
import { baseUrl, rupiah } from "../utils/strings";
import { useNavigate } from "react-router-dom";
import RowTableMessage from "./RowTableMessage";
import LoadingTable from "./LoadingTable";
import { InputGroup, InputGroupCurrency } from "./Input";

export default function PilihDiskon() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const promo = JSON.parse(localStorage.getItem("promo"));
  const [idPromo, setIdPromo] = useState(promo ? promo.id : "");
  const [discountType, setDiscountType] = useState("");
  const [nominal, setNominal] = useState("");

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  useEffect(() => {
    if (discountType !== "" && nominal !== "") {
      localStorage.setItem(
        "diskon",
        JSON.stringify({
          tipe_diskon: discountType,
          nominal: nominal,
        })
      );
    }
  }, [discountType, nominal]);

  return (
    <>
      <div className="px-4 flex flex-col flex-1 mt-4">
        <div className="mb-6">
          <h3 className="font-bold mb-1">Pilih Tipe Diskon</h3>
          <p className="text-xs">
            Pilih tipe diskon di pilihan yang tersedia dibawah ini!
          </p>
        </div>
        <div className="flex justify-content-center mb-5">
          <div className="w-full my-1 mr-1">
            <div className="relative">
              <input
                type="radio"
                name="diskon"
                value="persentase"
                id="persentase"
                className="hidden peer"
                onChange={handleDiscountTypeChange}
                checked={discountType == "persentase"}
              />
              <label
                htmlFor="persentase"
                className="flex items-center gap-4 p-4 rounded-xl bg-slate-50 bg-opacity-90 backdrop-blur-2xl shadow-xl hover:bg-opacity-75 peer-checked:bg-blue-500 peer-checked:text-white cursor-pointer transition"
              >
                {/*<img
                  src={baseUrl + obj.gambar}
                  alt="Produk"
                  className="w-10 h-10 object-cover rounded-full"
                />*/}
                <h6 className="text-base font-bold">persentase</h6>
              </label>
              <div className="flex absolute top-0 right-4 bottom-0 w-7 h-7 my-auto rounded-full bg-blue-400 scale-0 peer-checked:scale-100 transition delay-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-5 text-white my-auto mx-auto"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full my-1 ml-1">
            <div className="relative">
              <input
                type="radio"
                name="diskon"
                value="nominal"
                id="nominal"
                className="hidden peer"
                onChange={handleDiscountTypeChange}
                checked={discountType == "nominal"}
              />
              <label
                htmlFor="nominal"
                className="flex items-center gap-4 p-4 rounded-xl bg-slate-50 bg-opacity-90 backdrop-blur-2xl shadow-xl hover:bg-opacity-75 peer-checked:bg-blue-500 peer-checked:text-white cursor-pointer transition"
              >
                {/*<img
                  src={baseUrl + obj.gambar}
                  alt="Produk"
                  className="w-10 h-10 object-cover rounded-full"
                />*/}
                <h6 className="text-base font-bold">nominal</h6>
              </label>
              <div className="flex absolute top-0 right-4 bottom-0 w-7 h-7 my-auto rounded-full bg-blue-400 scale-0 peer-checked:scale-100 transition delay-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="w-5 text-white my-auto mx-auto"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
          <div className={!discountType ? 'hidden' : "w-full mb-5"}>
            <label className="label">
              <span className='label-text text-xs'>Nominal Diskon</span>
            </label>
          {(discountType == "persentase") ? (
            <InputGroup
              type="text"
              directionIcon="right"
              name="nominal_diskon"
              icon="%"
              onChange={(e) => {
                if (e.target.value > 2) {
                  e.target.value = e.target.value.slice(0, 2);
                  setNominal(e.target.value);
                }
              }}
              placeholder="Nominal Diskon"
            />
          ) : (
            <InputGroupCurrency
              type="text"
              directionIcon="left"
              name="nominal_diskon"
              icon="IDR"
              onChange={(e) => setNominal(e.target.value)}
              placeholder="Nominal Diskon"
            />
          )}
        </div>
      </div>
    </>
  );
}
