import React, { useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export const options = {
  responsive: true,
  fill: true,
  tension: 0.4,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function createGradient(ctx, area) {
  const colorStart = "#C8CCFF";
  const colorEnd = "#7373BC";

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const ChartBar = ({ data, title }) => {
  const chartRef = useRef(null);
  const chart = chartRef.current;

  const labels = data.map((value) => value.label.slice(0, 3));

  const dataChart = {
    labels,
    datasets: [
      {
        label: title,
        fill: true,
        tension: 0.3,
        data: data.map((value) => value.value),
        backgroundColor: chart == null ? "#7373BC" : createGradient(chart.ctx, chart.chartArea),
      },
    ],
  };

  return (
    <Line options={options} ref={chartRef} data={dataChart} />
  );
};

export default ChartBar;
