import React, { useState, useEffect, useRef } from "react";
import "./setting.css";

// components
import HeaderContent from "../../../layouts/HeaderContent";
import LoadingPage from "../../../components/LoadingPage";
import { Button } from "../../../components/Button";
import { InputGroup, InputGroupCurrency } from "../../../components/Input";

// icons
import { HiOutlineReceiptTax } from "react-icons/hi";
import { BiGitBranch } from "react-icons/bi";
import { FiSave } from "react-icons/fi";

// libraries
import { useQuery } from "react-query";
import axios from "../../../utils/axios";
import { useForm } from "react-hook-form";
import { rupiahToNumber } from "../../../utils/strings";
import { useMutation, QueryClient } from "react-query";
import { toastSuccess, toastError } from "../../../utils/toast";
import moment from "moment";

const Pembayaran = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [isAction, setIsAction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbs = [
    { link: "/", menu: "Home" },
    { link: "/setting", menu: "Setting" },
    { link: "/setting/pembayaran", menu: "Pembayaran" },
  ];

  moment.locale("id");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    control,
    setValue,
  } = useForm();

  // fetch data
  const fetchData = async () => {
    setIsLoading(true)
    axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    const response = await axios.get(`setting`);
    const res = await response.data.data;

    setValue("metode_pembayaran", res?.metode_pembayaran || "");

    setIsLoading(false)
  };

  useEffect(() => {
    fetchData()
  }, [])

  const mutation = useMutation(
    async (data) => {
      const config = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      const response = await axios.post("setting", data, config);
      const res = response.data;

      if (res.meta.code != 200) {
        throw new Error(res.meta.message);
      }

      return res.data;
    },
    {
      onMutate: () => {
        // spinner
        setIsAction(true);
      },
      onSettled: async (data, error) => {
        setIsAction(false);
      },
      onSuccess: async () => {
        toastSuccess("Setting Pembayaran Berhasil Diubah");
      },
      onError: async () => {
        toastError("Setting Pembayaran Gagal Diubah");
      },
    }
  );

  const ubahSetting = async (data) => await mutation.mutate(data);

  if (isLoading)
    return (
      <div className="flex-1 flex justify-center items-center flex-col space-y-3">
        <LoadingPage />
      </div>
    );

  return (
    <>
      <HeaderContent title="Setting" breadcrumbs={breadcrumbs} />
      <div
        className="bg-white h-max px-6 rounded-lg h-96 mt-4 grid grid-cols-8 gap-4 relative"
        key={1}
      >
        <div className="md:col-span-5 col-span-12 shadow-lg shadow-blue-100 rounded px-6 py-10 rounded">
          <form onSubmit={handleSubmit(ubahSetting)}>
            <h1 className="text-md font-bold mb-2">Metode Pembayaran Self Service</h1>
            <div className="setting__content space-y-5">
              <div className="setting__item flex justify-between items-center">
                <div>
                  <p className="text-xs text-slate-400">
                    Metode pembayaran untuk transaksi secara mandiri oleh konsumen
                  </p>
                  <div className="form-group flex items-center mt-2 space-x-4">
                    <div className="form-control">
                      <label className="label cursor-pointer space-x-4">
                        <span className="label-text">Aktif</span>
                        <input
                          type="radio"
                          name="metode_pembayaran"
                          value="self_service"
                          className="radio radio-secondary"
                          {...register("metode_pembayaran")}
                        />
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer space-x-4">
                        <span className="label-text">Non Aktif</span>
                        <input
                          type="radio"
                          name="metode_pembayaran"
                          value="offline"
                          className="radio radio-secondary"
                          {...register("metode_pembayaran")}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Button
              className="text-xs mr-2 mt-4 bg-custom-purple border-custom-purple"
              color="secondary"
              type="submit"
              startIcon={<FiSave size={16} />}
              loading={isAction}
              title="Simpan"
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Pembayaran;
